.content-icon i {
  font-size: 2rem;
  cursor: pointer;
}

.main-search, .products {
  flex: 1;
}

.lateral-bar{
  color: gray;
  font-weight: bold;
  font-size: 15px;
}

.item-section-items {
  color: gray;
  font-weight: bold;
}

.toggle-categories {
  background-color: #b7b7b7;
  border-color: #b7b7b7;
}