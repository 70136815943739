.title {
    margin: 40px 0 20px 0;
    width: 100%;
    text-align: center;
}
.kr-payment-button{
    &::after{
     content: none;   
    }
}
/* .container {
    display: flex;
    justify-content: center;
} */