a.bar_content{
    cursor: pointer;
}

.bar{
    height: 40px;
}

.btn-xs {
  padding: .25rem .4rem;
  font-size: .875rem;
  line-height: .5;
  border-radius: .2rem;
  margin-top: 10px;
}

.gradiente {
    background: rgb(20,15,101);
background: linear-gradient(283deg, rgba(20,15,101,1) 0%, rgba(18,18,129,1) 50%, rgba(0,212,255,1) 100%);
}

.red {
    color: 'red' 
}

.white {
    color: 'white'
}