.not-active {
    pointer-events: none;
    cursor: default;
}

.table{
    height: 70px;
    vertical-align: middle;
}

.totalfinal{
    font-size: 18px;
    font-weight: bold;
    color: #093467;
    vertical-align: middle;
}

.titulo {
    font-weight: bold;
    font-size: 15px;
    color: rgb(29, 29, 29);
}

.transferencia {
    color: #009640;
    font-weight: bold;
    font-size: 15px;
}

.transferencia2 {
    color: violet;
    font-weight: bold;
    font-size: 15px;
}

.paypal {
    color: #009cde;
    font-weight: bold;
    font-size: 15px;
}
.izipay {
    color: #f8485e;
    font-weight: bold;
    font-size: 15px;
}
.pagoefectivo {
    color: #b59308;
    font-weight: bold;
    font-size: 15px;
}