// Datepicker

.react-datepicker-wrapper {
    display: block;
    width: 100% !important;
}
.react-datepicker__current-month--hasYearDropdown{
	display: none !important;
}
.container-datepicker {
	border-radius: 3px;
	background-color: $input !important;
	outline: 0px !important;
	box-shadow: none !important;
	border: 0px !important;
	font-size: 14px;
	padding-top: 5px !important;
	padding-bottom: 5px !important;
	min-width: 100%;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	// margin-bottom: 10px;
	padding-right: 10px;
	padding-left: 10px;
	&.white {
		background-color: $white !important;
	}
	p {
		min-width: 100%;
		margin: 10px;
	}
	i.fa {
		position: absolute;
		right: 10px;
	}
}

.label-datepicker {
	display: block;
}