// Variables

$gray: #e8e8e8;
$blue: #033268;
$black: #222d32;
$white: #fff;
$input: #f1f1f1;
$active: #0d1214;
$danger: #e53935;
$green: #43A047;
$orange: #FB8C00;