.title {
    margin: 40px 0 20px 0;
    width: 100%;
    text-align: center;
}
.btn-payment{
    width: 70%;
}
/* .container {
    display: flex;
    justify-content: center;
} */