.legales {
    color: black !important;
    padding: 50px;

    text-align : justify;
}

.containerl {
    color: black !important;
    padding: 100px;
    background: #e6e6e6;

}

.legales p {
    color: black !important;
}



