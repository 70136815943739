.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 25px;
  height: 25px;
}

.spinner:after {
  margin: auto;
  content: " ";
  display: block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 3px solid #F16F43;
  border-color: #F16F43 transparent #F16F43 transparent;
  animation: lds-dual-ring 0.5s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }

  .menos {
    margin-top: -10px;
  }
}
