.text-2rem{
    font-size: 2rem !important;
}        

/*{!!this.state.code ? (
                        <h3 className="text-info mt-4">
                          Felicidades usted se ha ganado un codigo de descuento
                          de envio de un solo uso
                          <span className="ml-1 font-weight-bold mr-1">
                            (solo Lima),
                          </span>
                          su codigo es
                          <br />
                          <span className="font-weight-bold text-success">
                            {this.state.code}
                          </span>
                        </h3>
                      ) : (
                        ''
                      )} */