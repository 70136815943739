.izipaylogo{
    width: 26px;
    height: 26px;
    border-radius: 22px;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    -ms-border-radius: 22px;
    -o-border-radius: 22px;
}

.fondo {
    color: #033268
}