/* .modal-backdrop {
    background-color: #00000005 !important;
}

.modalbody {
    background-color: transparent !important;
} */

.content-lightBox {
    width: 100%;
    height: auto;
    max-width: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 1rem;
    background: white
}

.content-lightBox img {
    width: 100%;
}