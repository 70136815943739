.main-navbar{
    .navbar-nav li:hover > ul.dropdown-menu {
        display: block;
    }
    .dropdown-submenu {
        position:relative;
    }
    .dropdown-submenu>.dropdown-menu {
        top: 0;
        left: 100%;
        margin-top:-6px;
    }
    
    /* rotate caret on hover */
    .dropdown-menu > li > a:hover:after {
        text-decoration: underline;
        transform: rotate(-90deg);
    }
    .dropdown-menu{
        width: 20vw;
        left: -45vw;
        .dropdown-submenu{
            width: 100%;
            .dropdown-menu{
                li{
                    width: 100%
                }
            }
        }
        li:not(.dropdown-submenu){
            display: block;
            width: 100%;
            .dropdown-item{
                width: 100%;
            }
        }
    }
    .category-nav{
        p:hover{
            color: $blue !important;
            transition: all .3s !important;
        }
    }
    .subcategory-nav{
        img{
            max-height: 70%;
            object-fit: contain;
        }
    }
    .nav-link{
        display: block;
        padding-right: 45px;
        color: #222222 !important;
        font-size: 18px;
        font-weight: bold;
        line-height: 1px;
        text-decoration: none;
        text-transform: uppercase;
        padding-top: 0px;
        padding-bottom: 5px;
        padding-left: 0;
        letter-spacing: 0.07em;
        position: relative;
    }
    .SlideModal{
        width: 100%;
        max-height: 90%;
        top: 22vh;
        .h-overflowAuto{
            overflow-x: hidden !important;
            .left-menu{
                border-right: solid 1px gray;
            }
        }
        img.bordered{
            border-radius: 10px;
        }
    }
}