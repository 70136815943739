
  body {
    counter-reset: section;
}

body {
  counter-reset: section;
}

.tarjeta {
  background-color: #0a62b5;
  color: white;
  height: 100px;
  padding: 10px;
}

.tarjeta p {
  color: white;
  margin-top: -5px;
}

.tarjeta h4 {
  font-style: bold;
  color: white;
}

.tallas
{
  border-collapse: collapse;
}
.tallas thead th,  .tallas tbody td
{
  border:1px solid #333;
  text-align: center;
  margin: -1px;
  padding: 10px;

}
.tallas tbody td
{
  background-color: #fff;
}
.tallas thead th
{
  color: #fff;
  background-color: #093467;


}
.tallas .bordered
{
  border-left: 2px solid #000;
  border-right: 2px solid #000;
}
.left
{
  width: 30%;
  display: inline-block;
  text-align: center;
}
.centrado
{
  width: 40%;
  display: inline-block;
  text-align: center;
}
.right
{
  width: 28%;
  display: inline-block;
  text-align: center;
}
.medidas
{
  
  padding: 10px;
}

.medidas h6
{
  color: #333;
  font-size: 20px;
}
.red2

{
  color: #093467;
}