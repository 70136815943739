@import '../../utils/breakpoints';

.content-icon i {
  font-size: 2rem;
  cursor: pointer;
}

.main-search {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr;
  @include md {
    grid-template-columns: 20% 1fr;
  }
  .lateral-bar {
    color: gray;
    font-weight: bold;
    .section-items {
      .title-section-items,
      .item-section-items {
        margin: 0;
        margin-bottom: 1rem;
        font-size: 1.167rem;
      }
      .title-section-items {
        text-transform: uppercase;
        color: black;
      }
      .item-section-items {
        color: gray;
        border-bottom: 0.063rem solid gray;
        padding-bottom: 0.75rem;
        cursor: pointer;
        &:hover {
          color: #197ee2;
          border-bottom: 0.063rem solid #197ee2;
          transition: 1s;
          -webkit-transition: 1s;
          -moz-transition: 1s;
          -ms-transition: 1s;
          -o-transition: 1s;
        }
      }
      .loading {
        color: gray;
      }
    }
  }
  .products {
    flex: 1;
  }
}
