.content-method{
    // max-width: 200px;
    img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}


.bg-green{
    background-color: #65c56578;
}