.registerHash {
  .content-icon {
    .success {
      color: #007bff;
    }
    .error {
      color: red;
    }
    i {
      font-size: 5.333rem;
    }
  }
  .content-info{
    .title {
      font-size: 2rem;
    }
  }
}
