body,
html { overflow-x: hidden; }

.top-animation{
    .bar {
      top: 0;
      left: 0;
      width: 100%;
      background-color: #033268;
      color: white;
      font-family: Gelasio;
      font-size: 18px;
      display: -webkit-box;
      padding: 10px;
    }
    .bar a {
      color: #fff !important
    }
    
    .bar_content {
      display: block; /* Important to give the content a width */
    
      width: 100%;
      transform: translateX(100%); /* Animation start out of the screen */
    
      /* Add the animation */
      animation: move 30s linear infinite /* infinite make reapeat the animation indefinitely */;
    }
    
    /* Create the animation */
    @keyframes move {
      to { transform: translateX(-100%); }
    }

    .bar_content:hover {
      -webkit-animation-play-state: paused; /* Safari 4.0 - 8.0 */
        animation-play-state: paused;
    }
}