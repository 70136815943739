.btn-flotante {
    font-size: 3rem;
    /* Cambiar el tamaño de la tipografia */
    text-transform: uppercase;
    /* Texto en mayusculas */
    font-weight: bold;
    /* Fuente en negrita o bold */
    color: #ffffff;
    /* Color del texto */
    border-radius: 15px;
    /* Borde del boton */
    letter-spacing: 2px;
    /* Espacio entre letras */
    background-color: #0024c9;
    /* Color de fondo */
    padding: 10px 10px;
    /* Relleno del boton */
    position: fixed;
    bottom: 10px;
    width: 78px;
    right: 130px;
    transition: all 300ms ease 0ms;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    z-index: 99;
}

.btn-flotante2 {
    font-size: 3rem;
    /* Cambiar el tamaño de la tipografia */
    text-transform: uppercase;
    /* Texto en mayusculas */
    font-weight: bold;
    /* Fuente en negrita o bold */
    color: #ffffff;
    /* Color del texto */
    border-radius: 15px;
    /* Borde del boton */
    letter-spacing: 2px;
    /* Espacio entre letras */
   /* background-color: #0024c9;*/
    /* Color de fondo */
    padding: 10px 10px;
    /* Relleno del boton */
    position: fixed;
    bottom: 10px;
    width: 78px;
    right: 5px;
    transition: all 300ms ease 0ms;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    z-index: 99;
}




@media only screen and (max-width: 600px) {
    .btn-flotante {
        font-size: 14px;
        padding: 12px 20px;
        bottom: 20px;
        width: 60px;
        right: 92px;
    }

    .btn-flotante2 {
        font-size: 14px;
        padding: 12px 20px;
        bottom: 20px;
        width: 60px;
        right: 40px;
    }
}