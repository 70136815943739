.progressbar {
  counter-reset: step;
  padding: 0;
  
  /* for demo */
  margin: 45px auto 0;
  max-width: 100%;
  width: 100%;
}

.estadoenvio{
  vertical-align: middle;
  margin-top: 50px;
}

.progressbar li {
  float: left;
  list-style: none;
  position: relative;
  text-align: center;
  width: calc(100% / 5);
}

.progressbar li:before {
  background: #fff;
  border: 2px solid #bebebe;
  border-radius: 50%;
  color: #bebebe;
  content: counter(step);
  counter-increment: step;
  display: block;
  font-weight: 700;
  height: 30px;
  line-height: 27px;
  margin: 0 auto 10px;
  text-align: center;
  width: 30px;
}

.progressbar li:after {
  width: 85%;
  height: 8px;
  content: '';
  position: absolute;
  background-color: #979797;
  top: 12px;
  left: -43%;
  z-index: 0;
}




.progressbar li:first-child:after {
  content: none;
}

.progressbar li.active:after,
.progressbar li.complete:after {
  background: #178de8;
}

.progressbar li.active:before,
.progressbar li.complete:before {
  background: #178de8;
  border-color: #178de8;
  color: #fff;
}

.progressbar li.active {
  color: #178de8;
  font-weight: 700;
}

.progressbar li.active2 {
  color: red;
  font-weight: 700;
}

.progressbar li.complete2:before {
  background: red;
  border-color: red;
  color: #fff;
}

.tabla2{
  font-size: 12px;
  vertical-align: middle;
}
.medio{
  vertical-align: middle;
}