.product-list {
  width: 100%;
  border-bottom: 2px solid #e8e8e8;
  &.product-box {
    .img-wrapper {
      .img-fluid {
        min-height: 100px !important;
        margin-bottom: 1rem;
      }
    }
  }
  .info {
    padding: 2rem;
    .content-title {
      width: 100%;
      .title {
        color: black;
        text-transform: capitalize;
      }
    }
    .money{
        font-size: 1.125rem;
    }
  }
}

.product-detail{
  margin-top: -40px
}


