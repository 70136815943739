// Modal

.modal {
	outline: 0px !important;
	.modal-dialog {
		outline: 0px !important;
		max-width: 100% !important;
		min-width: 80% !important;
		padding: 5% 10%;
	}
	.modal-title {
		font-family: 'Roboto Bold';
		color: $white;
		font-size: 20px;
	}
	.modal-content {
		background-color: transparent !important;
		border-color: transparent !important;
	}
	.modal-header {
		border-bottom: 0px !important;
		background-color: $blue;
		border-top-right-radius: 2px !important;
		border-top-left-radius: 2px !important;
		button.close {
			outline: 0px !important;
			color: $white !important;
		}
	}
	.modal-body {
		background-color: $white;
		border-bottom-right-radius: 2px !important;
		border-bottom-left-radius: 2px !important;
		padding: 2% !important;
	}
}